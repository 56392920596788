@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700");
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex: 0 0 auto; }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0;
    width: auto; }
  .grid-x > .medium-shrink, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex: 0 0 auto; }
  .grid-x > .medium-shrink {
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0;
    width: auto; }
  .grid-x > .large-shrink, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex: 0 0 auto; }
  .grid-x > .large-shrink {
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 1.25rem / 2); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 1.25rem / 2); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 1.25rem / 2); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 1.25rem / 2); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 1.25rem / 2); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 1.25rem / 2); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 1.25rem / 2); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 1.25rem / 2); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 1.25rem / 2); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 1.25rem / 2); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 1.25rem / 2); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 1.25rem / 2); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.875rem / 2); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 1.875rem / 2); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 1.875rem / 2); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.875rem / 2); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 1.875rem / 2); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 1.875rem / 2); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.875rem / 2); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 1.875rem / 2); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 1.875rem / 2); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.875rem / 2); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 1.875rem / 2); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 1.875rem / 2); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.875rem / 2); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 1.875rem / 2); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 1.875rem / 2); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.875rem / 2); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 1.875rem / 2); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 1.875rem / 2); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.875rem / 2); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 1.875rem / 2); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 1.875rem / 2); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.875rem / 2); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 1.875rem / 2); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 1.875rem / 2); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    height: auto;
    max-height: none; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex: 0 0 auto; }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0;
      height: auto; }
    .grid-y > .medium-shrink, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex: 0 0 auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0;
      height: auto; }
    .grid-y > .large-shrink, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex: 0 0 auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
  width: auto; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
    width: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
    width: auto; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem); } }

/*
//cf. https://swiperjs.com/api/#styles
@import "~swiper/swiper";
@import "~swiper/components/lazy/lazy";
// etc...
*/
/*
//Browsers
ie,
edge,
chrome,
opera,
firefox,
safari,
vivaldi,

//Specific Browsers
chromeIOS,
ieMobile,

//Platforms
windows,
mac,
linux,
android,
blackberry,
ios,

//Type
desktop,
mobile,
*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  margin-bottom: 0 !important; }
  @media screen and (min-width: 80em) {
    body {
      font-size: 16px;
      font-size: 1rem; } }

img {
  max-width: 100%;
  height: auto; }

strong {
  font-weight: 600; }

em {
  font-style: italic; }

picture {
  display: block;
  overflow: hidden; }
  picture img {
    display: inline-block;
    margin: auto;
    width: 100%;
    filter: blur(10px);
    transform: scale(1.03);
    transition: transform 0.15s, filter 0.15s; }
    picture img.lazyloaded {
      filter: blur(0px);
      transform: scale(1); }

body {
  scroll-behavior: smooth; }

@keyframes slideEnter {
  from {
    opacity: 0;
    transform: scale(0.2); }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes slideLeave {
  from {
    opacity: 1;
    transform: scale(1); }
  to {
    opacity: 0;
    transform: scale(3); } }

.swiper {
  /* &-pagination{
		position: absolute;
		top: 0; bottom: 0; left: 0;
		height: 10px;
		background-color: $primary;
	} */ }
  .swiper-container {
    position: relative; }
  .swiper-wrapper {
    position: sticky;
    top: 0; }
  .swiper-slide {
    background-color: inherit;
    /* transform: scale(3);

		transition: 0.3s ease-in-out;
		transition-property: opacity, transform;
		animation-fill-mode: both;

		&.active{
			opacity: 1;
			transform: scale(1);
		} */ }

body {
  overflow-x: hidden; }

.stack__container {
  position: relative; }

.stack__wrapper, .stack__wrapper > * {
  position: sticky !important;
  top: 0; }
  html.ie .stack__wrapper, html.ie .stack__wrapper > * {
    position: relative; }
  html.safari.desktop .stack__wrapper, html.safari.desktop .stack__wrapper > * {
    position: relative; }

html.edge .stack__wrapper {
  position: relative !important; }

@media print, screen and (min-width: 48em) {
  .stack__wrapper .o-block, .stack__wrapper .o-block__container {
    height: 60vh !important; } }

.stack__wrapper .o-block--phrase, .stack__wrapper .o-block--phrase .o-block__container {
  height: 30vh !important; }

@media print, screen and (min-width: 48em) {
  .stack__wrapper .o-block--newsletter, .stack__wrapper .o-block--newsletter .o-block__container {
    height: auto !important; } }

.menu-local-tasks ul li:first-child {
  display: none; }

.menu-local-tasks ul li {
  margin-bottom: 10px; }

.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #ab242b;
  z-index: 99;
  background: #000;
  border-radius: 0 3px 0 0; }
  .tabs li {
    display: inline; }
  .tabs a {
    display: inline-block;
    padding: 8px 12px 6px;
    font-size: 12px;
    font-size: 0.75rem;
    color: #ab242b;
    text-decoration: none; }
    .tabs a:hover, .tabs a.active {
      border-bottom: 2px solid #ab242b; }

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px; }

.element-invisible.element-focusable:active, .element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto; }

.contentinfo {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #ab242b;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  max-width: 500px;
  text-align: center;
  cursor: pointer;
  line-height: 1.5em;
  transition: opacity 0.15s; }
  @media print, screen and (min-width: 64em) {
    .contentinfo {
      padding: 20px 50px;
      bottom: 20px;
      font-size: 17px;
      font-size: 1.0625rem; } }
  .contentinfo--error {
    background: #de3b2f; }
  .contentinfo--hidden {
    opacity: 0;
    pointer-events: none; }
  .contentinfo:after {
    content: '✕';
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 22px;
    height: 22px;
    font-size: 16px;
    font-size: 1rem;
    text-align: center;
    line-height: 22px;
    color: #fff; }
  .contentinfo a {
    color: #fff;
    text-decoration: none;
    font-weight: 600; }

.form-item-view-mode {
  display: none !important; }

a#edit-backlink, .menu-local-tasks ul li a {
  display: none; }
  @media print, screen and (min-width: 64em) {
    a#edit-backlink, .menu-local-tasks ul li a {
      display: inline-block;
      background: #041bb9;
      padding: 10px 25px;
      border-radius: 22px;
      border: 1px solid white;
      color: white;
      text-decoration: none;
      font-weight: 600; }
      a#edit-backlink:hover, .menu-local-tasks ul li a:hover {
        background: #031487; } }

#toolbar-administration {
  display: none; }
  @media print, screen and (min-width: 64em) {
    #toolbar-administration {
      display: block; } }

body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
  margin-left: 0 !important; }
  @media print, screen and (min-width: 64em) {
    body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
      margin-left: 240px !important;
      margin-left: 15rem !important; } }

.adminimal-admin-toolbar nav.menu-local-tasks {
  position: fixed;
  z-index: 499;
  box-sizing: border-box;
  width: 10%;
  padding: 10px;
  bottom: 0; }

.a-wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; }
  @media print, screen and (min-width: 35.5em) {
    .a-wrapper {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 48em) {
    .a-wrapper {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 64em) {
    .a-wrapper {
      padding-right: 0;
      padding-left: 0; } }
  .a-wrapper--fat {
    max-width: 1680px; }

.a-heading {
  line-height: 1.3em;
  font-weight: 600;
  color: #ab242b; }
  .a-heading--1 {
    font-size: 22px;
    font-size: 1.375rem;
    margin-top: 20px;
    margin-bottom: 20px; }
    @media print, screen and (min-width: 48em) {
      .a-heading--1 {
        font-size: 26px;
        font-size: 1.625rem; } }
    @media print, screen and (min-width: 64em) {
      .a-heading--1 {
        font-size: 32px;
        font-size: 2rem;
        line-height: 1.1em;
        margin-top: 50px;
        margin-bottom: 50px; } }
  .a-heading--2 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px; }
    @media print, screen and (min-width: 48em) {
      .a-heading--2 {
        font-size: 22px;
        font-size: 1.375rem; } }
    @media print, screen and (min-width: 64em) {
      .a-heading--2 {
        font-size: 26px;
        font-size: 1.625rem;
        margin-bottom: 50px; } }
    @media screen and (min-width: 80em) {
      .a-heading--2 {
        font-size: 32px;
        font-size: 2rem; } }
  .a-heading--3 {
    color: #000;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.4em; }
    @media print, screen and (min-width: 64em) {
      .a-heading--3 {
        font-size: 24px;
        font-size: 1.5rem;
        margin-bottom: 20px; } }
  .a-heading--4 {
    color: #000;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media print, screen and (min-width: 64em) {
      .a-heading--4 {
        font-size: 18px;
        font-size: 1.125rem; } }
  .a-heading--white {
    color: #fff; }
    .a-heading--white__bot {
      font-size: 36px !important;
      font-size: 36px !important/16pxrem; }
      @media print, screen and (min-width: 64em) {
        .a-heading--white__bot {
          font-size: 56px !important;
          font-size: 56px !important/16pxrem; } }
  .a-heading--primary {
    color: #ab242b; }
  .a-heading--secondary {
    color: #000; }
  .a-heading--no-top {
    margin-top: 0; }
  .a-heading--no-bot {
    margin-bottom: 0; }
  .a-heading--centered {
    text-align: center; }
  .a-heading--slim {
    font-weight: 400; }
  .a-heading--big {
    font-weight: bold;
    font-size: 48px !important;
    font-size: 48px !important/16pxrem; }
    @media print, screen and (min-width: 48em) {
      .a-heading--big {
        font-size: 64px !important;
        font-size: 64px !important/16pxrem; } }
    @media print, screen and (min-width: 64em) {
      .a-heading--big {
        font-size: 80px !important;
        font-size: 80px !important/16pxrem; } }
  .a-heading--home {
    font-weight: normal;
    font-size: 25px;
    font-size: 1.5625rem; }
    @media print, screen and (min-width: 64em) {
      .a-heading--home {
        font-size: 40px;
        font-size: 2.5rem; } }
    .a-heading--home-first {
      font-weight: bold; }
  .a-heading__sub {
    display: block; }

.a-text {
  line-height: 1.5em; }
  .a-text--white {
    color: #fff; }
  .a-text--primary {
    color: #ab242b; }
  .a-text--secondary {
    color: #000; }
  .a-text--fat {
    font-size: 15px;
    font-size: 0.9375rem; }
    @media screen and (min-width: 80em) {
      .a-text--fat {
        font-size: 17px;
        font-size: 1.0625rem; } }
  .a-text--slim {
    font-size: 12px;
    font-size: 0.75rem; }
    @media print, screen and (min-width: 64em) {
      .a-text--slim {
        font-size: 14px;
        font-size: 0.875rem; } }
  .a-text--centered {
    text-align: center; }
  .a-text__primary {
    color: #ab242b; }
  .a-text p {
    margin-bottom: 20px; }
  .a-text ul {
    list-style-type: square;
    margin-left: 1.25em; }
  .a-text li {
    margin-bottom: 5px; }
  .a-text a:not(.a-btn) {
    color: #ab242b; }
  .a-text > *:first-child {
    margin-top: 0; }
  .a-text > *:last-child {
    margin-bottom: 0; }

.a-btn {
  display: inline-block;
  height: 40px;
  line-height: 36px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  background: #ab242b;
  color: #fff;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  border: 2px solid #ab242b;
  box-sizing: border-box;
  cursor: pointer; }
  @media print, screen and (min-width: 48em) {
    .a-btn {
      height: 46px;
      line-height: 42px;
      padding-right: 50px;
      padding-left: 50px;
      border-radius: 23px; } }
  @media print, screen and (min-width: 64em) {
    .a-btn {
      font-size: 16px;
      font-size: 1rem; } }
  @media screen and (min-width: 80em) {
    .a-btn {
      transition: background-color 0.15s; } }
  @media screen and (min-width: 80em) {
    .a-btn:hover {
      background-color: #d32f37;
      border-color: #d32f37; } }

.a-btn--border {
  border: 2px solid #ab242b;
  background: transparent;
  color: #ab242b; }
  @media screen and (min-width: 80em) {
    .a-btn--border:hover {
      background-color: #ab242b;
      color: #fff; } }

.a-btn--red {
  color: white;
  background: #d41d24;
  border: 0;
  border-radius: 10px;
  background-image: linear-gradient(to right, #fc1255 -52%, #d41d24 30%, #ab2328 100%);
  line-height: 40px; }
  @media print, screen and (min-width: 48em) {
    .a-btn--red {
      line-height: 46px; } }
  .a-btn--red:hover {
    color: white;
    background: #ab242b;
    border-color: #ab242b; }

.a-btn--white {
  color: #a8252b;
  background: white;
  border: 0;
  border-radius: 10px;
  line-height: 40px; }
  @media print, screen and (min-width: 48em) {
    .a-btn--white {
      line-height: 46px; } }
  .a-btn--white:hover {
    color: white;
    background: #ab242b;
    border-color: #ab242b; }

.a-link {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #000;
  text-decoration: none;
  position: relative;
  outline: none;
  margin-top: 20px;
  padding-top: 2px;
  padding-left: 25px; }
  @media print, screen and (min-width: 64em) {
    .a-link {
      font-size: 16px;
      font-size: 1rem; } }
  @media screen and (min-width: 80em) {
    .a-link {
      transition: color 0.15s; } }
  @media screen and (min-width: 80em) {
    .a-link:hover {
      color: #000; } }
  .a-link:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background: #000;
    border-radius: 50%; }
  .a-link:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 3.5px;
    transform: translateY(-50%) rotate(-45deg);
    width: 6px;
    height: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    box-sizing: border-box; }

.a-image img {
  display: inline-block;
  margin: auto; }

.a-video__container {
  position: relative;
  z-index: 1;
  padding-bottom: 56.25%;
  margin: auto; }

.a-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f2f2f2; }

.m-gallery {
  display: flex;
  flex-flow: row wrap;
  margin-right: -25px;
  margin-bottom: -50px;
  margin-left: -25px; }
  .m-gallery__item {
    flex: 0 0 auto;
    width: calc(50% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    margin-bottom: 50px; }
    @media print, screen and (min-width: 48em) {
      .m-gallery__item {
        flex: 0 0 auto;
        width: calc(33.33333% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
  .m-gallery__image {
    background: #f2f2f2; }
  .m-gallery img {
    display: block;
    width: 100%; }

.m-cards {
  display: flex;
  flex-flow: row wrap;
  margin-right: -25px;
  margin-bottom: -40px;
  margin-left: -25px; }
  .m-cards__card {
    flex: 0 0 auto;
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 48em) {
      .m-cards__card {
        flex: 0 0 auto;
        width: calc(50% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        text-align: center; } }
    @media print, screen and (min-width: 64em) {
      .m-cards__card {
        flex: 0 0 auto;
        width: calc(33.33333% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
    @media screen and (min-width: 80em) {
      .m-cards__card {
        flex: 0 0 auto;
        width: calc(25% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
  .m-cards img {
    display: inline-block;
    width: 160px;
    margin-bottom: 20px; }

.m-logos {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -50px; }
  .m-logos__logo {
    flex: 0 0 auto;
    width: calc(50% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    position: relative;
    margin-bottom: 50px; }
    @media print, screen and (min-width: 48em) {
      .m-logos__logo {
        flex: 0 0 auto;
        width: calc(33.33333% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
    @media print, screen and (min-width: 64em) {
      .m-logos__logo {
        flex: 0 0 auto;
        width: calc(25% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
  .m-logos img {
    display: inline-block;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto; }
  .m-logos__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.m-nav-main__item {
  margin-bottom: 10px; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px; } }
  .m-nav-main__item:last-child {
    margin-bottom: 0; }
    @media print, screen and (min-width: 64em) {
      .m-nav-main__item:last-child {
        margin-right: 0; } }

.m-nav-main__link {
  color: #333333; }
  @media screen and (min-width: 80em) {
    .m-nav-main__link {
      transition: color 0.15s; } }
  .m-nav-main__link.is-active {
    color: #ab242b; }
  @media screen and (min-width: 80em) {
    .m-nav-main__link:hover {
      color: #000; } }

.m-nav-social__item {
  display: inline-block;
  margin-right: 10px; }
  .m-nav-social__item:last-child {
    margin-right: 0; }

.m-nav-social__logo {
  width: 40px;
  height: 40px;
  fill: #000; }
  @media screen and (min-width: 80em) {
    .m-nav-social__logo {
      transition: fill 0.15s; } }
  @media screen and (min-width: 80em) {
    .m-nav-social__logo:hover {
      fill: #333333; } }

.m-nav-footer__item {
  margin-bottom: 10px; }
  .m-nav-footer__item:last-child {
    margin-bottom: 0; }

.m-nav-footer__link {
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
  text-decoration: none; }
  @media screen and (min-width: 80em) {
    .m-nav-footer__link {
      transition: color 0.15s; } }
  .m-nav-footer__link:hover {
    text-decoration: underline; }

@media print, screen and (min-width: 64em) {
  .m-nav-footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end; }
    .m-nav-footer__item {
      flex: 0 0 auto;
      width: calc(33.33333% - 3.125rem);
      margin-left: 1.5625rem;
      margin-right: 1.5625rem;
      margin: 0;
      max-width: 33%;
      width: auto;
      margin-right: 1rem; } }

.m-form {
  margin-top: 20px; }
  .m-form label {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.3em; }
    @media print, screen and (min-width: 64em) {
      .m-form label {
        font-size: 15px;
        font-size: 0.9375rem; } }
  .m-form .form-item {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    /* &:last-child {
            margin-bottom: 0;
		} */ }
    @media screen and (min-width: 43.75em) {
      .m-form .form-item {
        max-width: 50%; } }
    @media print, screen and (min-width: 64em) {
      .m-form .form-item {
        max-width: 40%; } }
  .m-form input[type="text"],
  .m-form input[type="password"],
  .m-form input[type="email"],
  .m-form input[type="tel"],
  .m-form select,
  .m-form textarea {
    display: block;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 5px;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    color: #fff;
    text-align: center;
    outline: none; }
    @media print, screen and (min-width: 64em) {
      .m-form input[type="text"],
      .m-form input[type="password"],
      .m-form input[type="email"],
      .m-form input[type="tel"],
      .m-form select,
      .m-form textarea {
        padding: 15px 10px;
        font-size: 25px;
        font-size: 1.5625rem; } }
    .m-form input[type="text"]::placeholder,
    .m-form input[type="password"]::placeholder,
    .m-form input[type="email"]::placeholder,
    .m-form input[type="tel"]::placeholder,
    .m-form select::placeholder,
    .m-form textarea::placeholder {
      color: rgba(255, 255, 255, 0.8); }
  .m-form input[type="checkbox"], .m-form input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: solid 2px #fff;
    vertical-align: middle;
    margin: 0;
    margin-top: -4px;
    margin-right: 5px;
    color: #fff;
    cursor: pointer;
    outline: none;
    position: relative; }
    .m-form input[type="checkbox"]::before, .m-form input[type="radio"]::before {
      content: '✔';
      color: #008000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      font-size: 0.75rem;
      display: none; }
    .m-form input[type="checkbox"]:checked, .m-form input[type="radio"]:checked {
      background: #fff; }
      .m-form input[type="checkbox"]:checked::before, .m-form input[type="radio"]:checked::before {
        display: block; }
  .m-form input[type="radio"] {
    border-radius: 50%; }
  .m-form input[type="submit"] {
    cursor: pointer;
    display: block;
    font-family: "Montserrat", sans-serif;
    outline: none;
    margin: 0;
    appearance: none;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    font-weight: 600;
    margin: auto;
    font-size: 17px;
    font-size: 1.0625rem;
    transition: .3s ease-in-out;
    transition-property: background-color, color; }
    .m-form input[type="submit"]:hover {
      background-color: white;
      color: #000; }
  .m-form .form-item--error-message {
    color: #de3b2f;
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 10px; }
    .m-form .form-item--error-message a {
      color: #000; }
  .m-form #edit-mentions {
    font-size: 12px;
    font-size: 0.75rem;
    color: #fff;
    margin-top: 10px;
    line-height: 1.5em;
    text-align: center;
    margin-top: 0; }
    .m-form #edit-mentions a {
      color: #000; }
  .m-form .js-form-type-checkbox {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center; }
    .m-form .js-form-type-checkbox label {
      flex: 0; }
      .m-form .js-form-type-checkbox label.visually-hidden {
        display: none; }
    .m-form .js-form-type-checkbox input[type="checkbox"] {
      flex: 0 auto;
      margin-right: 1em;
      border-color: #fff; }
    .m-form .js-form-type-checkbox .description {
      color: #fff;
      flex: 1; }
      .m-form .js-form-type-checkbox .description a {
        color: inherit; }

.m-features {
  /* @include breakpoint(large){
		@include xy-grid;
	} */ }
  .m-features__group {
    /* height: 100vh;
		position: relative;
		overflow: hidden; */ }
    html.ie .m-features__group {
      position: relative; }
    html.edge .m-features__group {
      position: relative; }
  .m-features__feature {
    /* position: absolute;
		@include trbl(0); */
    position: sticky;
    top: 0;
    height: 35vh;
    text-align: center;
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.3em;
    font-weight: 600;
    color: #ab242b;
    background-image: url(../img/feature-grey.jpg);
    background-size: cover;
    /* height: 100vh;
		position: absolute;
		@include trbl(0);
		opacity: 0; */ }
    .m-features__feature:nth-child(3n+2) {
      background-image: url(../img/feature-red.jpg);
      background-color: #ab242b;
      color: white; }
    .m-features__feature:nth-child(3n+3) {
      background-image: url(../img/feature-blue.jpg);
      background-color: #000;
      color: white; }
    @media print, screen and (min-width: 48em) {
      .m-features__feature {
        font-size: 50px;
        font-size: 3.125rem; } }
    @media print, screen and (min-width: 64em) {
      .m-features__feature {
        font-size: 60px;
        font-size: 3.75rem; } }
    html.ie .m-features__feature {
      position: relative; }
      @media print, screen and (min-width: 48em) {
        html.ie .m-features__feature {
          height: 50vh; } }
    html.safari.desktop .m-features__feature {
      position: relative; }
      @media print, screen and (min-width: 48em) {
        html.safari.desktop .m-features__feature {
          height: 50vh; } }
    .m-features__feature span {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%); }
  .m-features__text {
    font-size: 40px;
    font-size: 2.5rem;
    text-align: center;
    position: relative;
    overflow: visible; }
    @media print, screen and (min-width: 64em) {
      .m-features__text {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
        font-size: 60px;
        font-size: 3.75rem; } }

.m-cookie-bar {
  padding: 20px;
  max-width: 500px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  text-align: center;
  background: #fff;
  position: fixed;
  right: 20px;
  bottom: 80px;
  left: 20px;
  z-index: 2; }
  @media print, screen and (min-width: 48em) {
    .m-cookie-bar {
      right: auto;
      bottom: 25px;
      left: 25px; } }
  .m-cookie-bar__img {
    display: inline-block;
    width: 100px;
    margin-bottom: 20px; }
    @media print, screen and (min-width: 64em) {
      .m-cookie-bar__img {
        width: 150px; } }
  .m-cookie-bar__buttons {
    margin-top: 20px; }
    .m-cookie-bar__buttons .a-text {
      display: inline-block;
      margin-bottom: 10px;
      text-decoration: none;
      cursor: pointer; }
      @media print, screen and (min-width: 64em) {
        .m-cookie-bar__buttons .a-text {
          margin-right: 10px; } }
    @media print, screen and (min-width: 64em) {
      .m-cookie-bar__buttons .a-btn {
        margin-left: 10px;
        vertical-align: middle;
        cursor: pointer; } }

.m-cookie-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
  transition: transform 0s 0.5s, opacity 0s 0.5s; }
  .m-cookie-sidebar--shown {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
    transition: transform 0s, opacity 0s; }
    .m-cookie-sidebar--shown .m-cookie-sidebar__mask {
      opacity: 1;
      cursor: pointer; }
    .m-cookie-sidebar--shown .m-cookie-sidebar__container {
      transform: translateX(0%); }
  .m-cookie-sidebar__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.5s; }
  .m-cookie-sidebar__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80%;
    max-width: 450px;
    background: #000;
    color: #fefefe;
    padding: 40px 20px;
    box-sizing: border-box;
    overflow: auto;
    transform: translateX(-100%);
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .m-cookie-sidebar__container .a-heading {
      color: inherit; }
      .m-cookie-sidebar__container .a-heading--no-top + .a-text {
        margin-top: 1rem;
        margin-bottom: 1rem; }
  .m-cookie-sidebar__switch {
    display: flex;
    flex-flow: row wrap;
    margin: 20px auto 40px; }
    .m-cookie-sidebar__switch--mandatory {
      opacity: 0.5;
      pointer-events: none; }
  .m-cookie-sidebar__option {
    flex: 0 0 auto;
    width: calc(33.33333% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    font-weight: 600;
    text-align: center; }
  .m-cookie-sidebar__switcher-container {
    flex: 0 0 auto;
    width: calc(33.33333% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    position: relative; }
  .m-cookie-sidebar__switcher {
    display: block;
    width: 38px;
    height: 20px;
    background: #1a1a1a;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    cursor: pointer; }
    .m-cookie-sidebar__switcher:after {
      content: '';
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      margin: 2px 0 0 2px;
      background: #fff;
      border-radius: 50%;
      box-sizing: border-box;
      transition: left 0.25s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }
    .m-cookie-sidebar__switcher[data-value="1"] {
      background: #008000; }
      .m-cookie-sidebar__switcher[data-value="1"]:after {
        left: 50%; }

.m-thanks__wrapper {
  width: 100%;
  margin: 0; }
  @media print, screen and (min-width: 64em) {
    .m-thanks__wrapper {
      width: auto;
      margin-left: auto;
      margin-right: auto; } }

.m-thanks__body, .m-404__body {
  display: block;
  font-weight: normal;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto; }
  @media print, screen and (min-width: 64em) {
    .m-thanks__body, .m-404__body {
      max-width: 70%; } }

.o-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 75px; }
  .o-header__container {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    align-items: center;
    /* @include browser("ipad.safari"){
			display: -webkit-box;
			-webkit-box-align: center;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
		} */ }
  .o-header__left {
    flex: 0 0 auto;
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    text-align: center; }
    @media print, screen and (min-width: 64em) {
      .o-header__left {
        flex: 0 0 auto;
        width: calc(66.66667% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-right: 0;
        display: flex;
        justify-content: flex-start;
        /* @include browser("ipad.safari"){
				display: -webkit-box;
				-webkit-box-pack: flex-start;
			} */ } }
  .o-header__right {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .o-header__right {
        flex: 0 0 auto;
        width: calc(33.33333% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-right: 0;
        display: flex;
        justify-content: flex-end;
        /* @include browser("ipad.safari"){
				display: -webkit-box;
				-webkit-box-pack: flex-end;
			} */ } }
    .o-header__right > * {
      padding-left: 20px !important;
      padding-right: 20px !important;
      font-size: 0.8rem !important; }
      @media screen and (min-width: 80em) {
        .o-header__right > * {
          font-size: 1rem !important;
          padding-left: 50px !important;
          padding-right: 50px !important; } }
  .o-header__logo {
    margin: 0 10px;
    height: 100%; }
  .o-header__text {
    display: none;
    color: #fff; }
    @media print, screen and (min-width: 64em) {
      .o-header__text {
        display: inline-block;
        height: 2em;
        line-height: 2em; } }

.o-top {
  background: #000;
  background-image: url(../img/bg.jpg);
  background-size: cover;
  background-position: center;
  /* padding-top: $padding;
    padding-bottom: $padding; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* @include browser("ipad.safari"){
		display: -webkit-box;
		-webkit-box-pack: center;
		-webkit-box-align: center;
	} */
  height: 100vh; }
  html.ie .o-top {
    display: block; }
  .o-top__version {
    display: block;
    font-size: 134px;
    font-size: 8.375rem;
    color: #fff;
    font-weight: bold;
    text-align: center; }
    @media print, screen and (min-width: 64em) {
      .o-top__version {
        font-size: 214px;
        font-size: 13.375rem; } }
  .o-top__arrow {
    position: absolute;
    bottom: 0;
    /* $header-height / 2 */
    left: 50%;
    padding: 37.5px;
    transform: translateX(-50%);
    /* @include browser("ipad.safari"){
			-webkit-transform: translateX(-50%);
		} */ }
  .o-top__container {
    height: 100vh; }
  .o-top__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* @include browser("ipad.safari"){
		display: -webkit-box;
		-webkit-box-pack: center;
		-webkit-box-align: center;
	} */
    flex-flow: column wrap;
    /* @include browser("ipad.safari"){
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
		} */ }

.o-block {
  margin-top: 80px;
  margin-bottom: 80px;
  height: 100vh; }
  @media screen and (min-width: 80em) {
    .o-block {
      margin-top: 120px;
      margin-bottom: 120px; } }
  .o-block--slim {
    margin-top: 20px;
    margin-bottom: 20px; }
    @media screen and (min-width: 80em) {
      .o-block--slim {
        margin-top: 40px;
        margin-bottom: 40px; } }
  .o-block--padded {
    margin: 0;
    padding-top: 80px;
    padding-bottom: 80px; }
    @media print, screen and (min-width: 64em) {
      .o-block--padded {
        padding-top: 120px;
        padding-bottom: 120px;
        margin-top: 0 !important;
        margin-bottom: 0 !important; } }
  .o-block--primary {
    background-color: #ab242b; }
  .o-block--secondary {
    background-color: #000; }
  .o-block--grey {
    background-color: #f2f2f2; }
  .o-block--black {
    background-color: #000; }
  .o-block--overflow-hidden {
    overflow: hidden; }
  .o-block--centered {
    text-align: center; }
  .o-block--button {
    text-align: center; }
  @media print, screen and (min-width: 64em) {
    .o-block--text-image-left .o-block__left {
      order: 2; } }
  .o-block--features {
    position: relative;
    background-image: linear-gradient(to bottom, #e8e6e6, #ffffff);
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: auto !important; }
  .o-block--discover {
    background: #ab242b;
    background-image: url(../img/bg-2.jpg);
    background-size: cover;
    background-position: center;
    /* @include breakpoint(large){
			@include verticalPadding(160px);
		} */
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .o-block--discover .o-block__container {
      height: 40vh; }
      @media print, screen and (min-width: 64em) {
        .o-block--discover .o-block__container {
          height: 100vh; } }
    .o-block--discover .o-block__content {
      display: flex;
      justify-content: center;
      align-items: center;
      /* @include browser("ipad.safari"){
		display: -webkit-box;
		-webkit-box-pack: center;
		-webkit-box-align: center;
	} */
      flex-direction: column; }
      @media print, screen and (min-width: 64em) {
        .o-block--discover .o-block__content {
          flex-direction: row; } }
  .o-block--phrase {
    background-color: white;
    position: relative;
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .o-block--phrase .o-block__container {
      height: 100vh; }
    .o-block--phrase .o-block__content {
      display: flex;
      justify-content: center;
      align-items: center;
      /* @include browser("ipad.safari"){
		display: -webkit-box;
		-webkit-box-pack: center;
		-webkit-box-align: center;
	} */ }
  .o-block--bot {
    height: auto; }
  .o-block--newsletter {
    height: auto;
    min-height: 100vh; }
  .o-block__container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 100%; }
  .o-block__content {
    flex: 0 0 auto;
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    margin-bottom: 40px; }
    .o-block__content:last-child {
      margin-bottom: 0; }
    @media print, screen and (min-width: 64em) {
      .o-block__content {
        margin-bottom: 60px; } }
    @media screen and (min-width: 80em) {
      .o-block__content {
        flex: 0 0 auto;
        width: calc(83.33333% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-left: calc(8.33333% + 3.125rem / 2); } }
    @media print, screen and (min-width: 64em) {
      .o-block__content--slim {
        flex: 0 0 auto;
        width: calc(83.33333% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-left: calc(8.33333% + 3.125rem / 2); } }
    @media screen and (min-width: 80em) {
      .o-block__content--slim {
        flex: 0 0 auto;
        width: calc(66.66667% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-left: calc(16.66667% + 3.125rem / 2); } }
    @media print, screen and (min-width: 64em) {
      .o-block__content--slimer {
        flex: 0 0 auto;
        width: calc(66.66667% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-left: calc(16.66667% + 3.125rem / 2); } }
    @media screen and (min-width: 80em) {
      .o-block__content--slimer {
        flex: 0 0 auto;
        width: calc(50% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-left: calc(25% + 3.125rem / 2); } }
    @media screen and (min-width: 80em) {
      .o-block__content--full {
        flex: 0 0 auto;
        width: calc(100% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
    @media print, screen and (min-width: 64em) {
      .o-block__content--top {
        flex: 0 0 auto;
        width: calc(75% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
    @media screen and (min-width: 80em) {
      .o-block__content--top {
        flex: 0 0 auto;
        width: calc(58.33333% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-left: calc(8.33333% + 3.125rem / 2); } }
  .o-block__content--noflex {
    flex-direction: column !important; }
    .o-block__content--noflex p {
      width: 50%;
      margin-bottom: 60px; }
  .o-block__left {
    flex: 0 0 auto;
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    margin-bottom: 60px; }
    @media print, screen and (min-width: 64em) {
      .o-block__left {
        flex: 0 0 auto;
        width: calc(50% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        margin-bottom: 0; } }
  .o-block__right {
    flex: 0 0 auto;
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem; }
    @media print, screen and (min-width: 64em) {
      .o-block__right {
        flex: 0 0 auto;
        width: calc(50% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
  .o-block__number {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .o-block__lift {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: hidden; }
    .o-block__lift span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 80vw;
      font-weight: 600;
      color: #e8e6e6;
      opacity: 0.5; }
    html.ie .o-block__lift {
      position: absolute;
      display: none; }
    html.safari.desktop .o-block__lift {
      position: absolute;
      display: none; }

.o-footer {
  background-color: #000;
  padding-top: 40px;
  padding-bottom: 40px; }
  .o-footer__container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    /* @include browser("ipad.safari"){
			display: -webkit-box;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-webkit-flex-flow: row wrap;
		} */ }
    @media print, screen and (min-width: 64em) {
      .o-footer__container {
        margin-bottom: 0; } }
  .o-footer__left {
    flex: 0 0 auto;
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    margin-bottom: 40px;
    text-align: center; }
    @media print, screen and (min-width: 48em) {
      .o-footer__left {
        flex: 0 0 auto;
        width: calc(50% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0;
        /* @include browser("ipad.safari"){
				display: -webkit-box;
				-webkit-box-pack: flex-start;
			} */ } }
  .o-footer__right {
    flex: 0 0 auto;
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
    text-align: center; }
    @media print, screen and (min-width: 48em) {
      .o-footer__right {
        flex: 0 0 auto;
        width: calc(50% - 3.125rem);
        margin-left: 1.5625rem;
        margin-right: 1.5625rem; } }
  .o-footer__logo {
    margin: 0 10px; }

@media print, screen and (min-width: 48em) {
  .o-main__slide {
    overflow: visible;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .o-main__slide--ready .o-main__progress {
    opacity: 1;
    pointer-events: auto; } }

.o-main__slide--animating .o-main__progress {
  pointer-events: none; }

.o-main__slide > video {
  display: none; }
  @media print, screen and (min-width: 48em) {
    .o-main__slide > video {
      display: block; } }
